
      @import 'styles-scss/abstracts/mixins';
    
.wrapper {
  width: 100%;
}

.list {
  display: grid;
  gap: var(--space-lg);

  @include break(767) {
    justify-items: center;
  }

  @include break-reverse(768) {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: var(--space-xl);
  }

  @include break-reverse(992) {
    gap: calc(var(--space-md) * 3);
  }
}

.item {
  .card {
    display: flex;
    flex-direction: column;
  }

  .card_title {
    margin-bottom: var(--space-sm);
    letter-spacing: 0.2px;
  }

  .card_description {
    max-width: 480px;
    margin-bottom: var(--space-md);
    color: var(--neutral-10);
  }

  .card_list {
    display: flex;
    align-items: center;
  }
}

.item__certificate {
  .card_item {
    &:last-of-type {
      margin: 0 0 0 var(--space-md);
    }

    & img {
      max-height: 88px;

      @include break-reverse(768) {
        max-height: 114px;
      }

      @include break-reverse(992) {
        max-height: 140px;
      }
    }

    & p {
      font-weight: 500;
      color: var(--neutral-10);
    }
  }
}

.item__deployment {
  .card_title {
    @include break-reverse(768) {
      order: 2;
    }
  }

  .card_description {
    @include break-reverse(768) {
      order: 3;
      margin: 0;
    }
  }

  .card_list {
    @include break-reverse(768) {
      order: 1;
      margin: 0 0 var(--space-md);
    }
  }

  .card_item {
    &:not(:first-of-type) {
      margin: 0 0 0 var(--space);
    }

    img {
      max-height: 56px;

      @include break-reverse(768) {
        max-height: 64px;
      }

      @include break-reverse(992) {
        max-height: 72px;
      }

      @include break-reverse(1240) {
        max-height: 80px;
      }
    }
  }
}
