
      @import 'styles-scss/abstracts/mixins';
    
.wrapper {
  padding-top: var(--space-lg);
  padding-bottom: calc(var(--space-md) * 2);

  @include break-reverse(768) {
    padding-bottom: calc(var(--space-md) * 3);
  }
}

.subtitle {
  margin-bottom: var(--space);
  color: var(--neutral-50);

  @include break-reverse(768) {
    margin-bottom: var(--space-md);
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  row-gap: var(--space);
  column-gap: var(--space-lg);
  margin-inline: auto;
  max-width: 970px;
  mix-blend-mode: luminosity;

  @include break-reverse(768) {
    row-gap: var(--space-md);
  }
}

.item {
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 0.85;

  &:has(.link:hover) {
    opacity: 1;
  }
}

.link {
  position: static;
  height: 100%;
  padding-bottom: 1px;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.icon {
  width: 100%;
}
