
      @import 'styles-scss/abstracts/mixins';
    
.wrapper {
  position: relative;
  max-width: 100%;
}

.title {
  margin-bottom: calc(var(--space-lg) * 0.5);

  @include break-reverse(768) {
    font-size: var(--header-xs-24);
  }
}

.subtitle {
  margin-bottom: var(--space-lg);
  color: var(--neutral-40);
}

.slider :global {
  width: 100%;

  .alice-carousel__stage-item {
    @include break-reverse(768) {
      &:nth-child(odd of .__active)::after {
        position: absolute;
        top: var(--space-sm);
        right: 0;
        content: '';
        width: 1px;
        height: calc(100% - var(--space-sm));
        background-color: var(--primary-60);
      }
    }

    @include break-reverse(992) {
      &:nth-child(odd of .__active)::after {
        content: none;
      }

      &.__active:not(:nth-child(3n))::after {
        position: absolute;
        top: var(--space-sm);
        right: 0;
        content: '';
        width: 1px;
        height: calc(100% - var(--space-sm));
        background-color: var(--primary-60);
      }
    }
  }

  .alice-carousel__dots {
    margin-top: var(--space-xs);

    @include break-reverse(768) {
      margin-top: var(--space);
    }
  }
}

.slide {
  position: relative;
  height: 100%;
  margin: 0 auto;
  padding-block: var(--space-sm);
  max-width: 480px;

  @include break(575) {
    padding-inline: var(--space-sm);

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: var(--primary-60);
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  @include break-reverse(768) {
    margin: 0 var(--space-sm);
  }
}

.card {
  justify-content: space-between;
  gap: var(--space);
  height: 100%;

  @include break-reverse(768) {
    gap: var(--space-lg);
  }

  .card_top {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card_review {
    max-width: 800px;
    color: var(--neutral-50);
  }

  .card_bottom {
    gap: var(--space);

    @include break-reverse(768) {
      gap: var(--space-lg);
    }
  }

  .card_author {
    gap: var(--space-sm);
  }

  .card_wrapper {
    position: relative;
  }

  .card_glow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 64px;
    height: 64px;
    opacity: 0.6;
    filter: blur(40px);

    @include break-reverse(390) {
      filter: blur(60px);
    }
  }

  .card_visual {
    padding: calc(var(--space-xxs) * 0.5);
    width: 60px;
    height: 60px;
    background: rgb(from var(--primary-70) r g b / 0.4);

    @include gradient-border($border-radius: 12px);
  }

  .card_image {
    border-radius: 12px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card_info {
    text-align: center;
  }

  .card_name {
    @include break-reverse(768) {
      font-size: var(--header-xxs-20);
    }
  }

  .card_caption {
    color: var(--neutral-35);

    @include break-reverse(576) {
      font-size: var(--text-md);
    }
  }

  .card_link {
    > span {
      gap: var(--space-xs);
    }

    &:hover .card_icon {
      stroke: var(--neutral-100);
    }
  }

  .card_icon {
    display: none;
    width: var(--space);
    height: var(--space);
    stroke-width: 1.2;
    stroke: currentColor;
    transition: all var(--transition);

    @include break-reverse(390) {
      display: block;
    }
  }
}
