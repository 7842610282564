
      @import 'styles-scss/abstracts/mixins';
    
.wrapper {
  position: relative;
}

.heading {
  margin-bottom: var(--space-xxl);

  @include break-reverse(768) {
    margin-bottom: var(--space-xl);
  }
}

.title {
  margin-bottom: var(--space);
}

.subtitle {
  max-width: 750px;
  margin-inline: auto;
  color: var(--neutral-40);
}

.awards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: var(--space);
  margin-inline: auto;

  @include break-reverse(768) {
    gap: var(--space-md);
  }
}

.image {
  height: 128px;
}
