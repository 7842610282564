
      @import 'styles-scss/abstracts/mixins';
    
.wrapper {
  display: grid;
  gap: var(--space-xl);

  @include break(767) {
    justify-items: center;
  }

  @include break-reverse(768) {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: var(--space-xl);
  }

  @include break-reverse(992) {
    gap: calc(var(--space-md) * 3);
  }
}

.title {
  margin-bottom: var(--space);

  @include break-reverse(768) {
    line-height: 1.25;
    text-align: left;
  }

  @include break-reverse(992) {
    font-size: var(--header-md-32);
  }
}

.description {
  gap: var(--space-sm);

  @include break-reverse(768) {
    align-items: flex-start;
  }
}

.subtitle {
  color: var(--neutral-40);

  @include break(767) {
    max-width: 600px;
  }

  @include break-reverse(768) {
    text-align: start;
    text-wrap: wrap;
    color: var(--neutral-10);
  }
}

.button {
  margin: var(--space-md) auto 0;

  @include break-reverse(768) {
    margin: var(--space-md) 0 0;
  }

  @include break-reverse(1240) {
    margin: var(--space-xl) 0 0;
  }
}

.visual {
  width: 110%;

  @include break(767) {
    mask-image: linear-gradient(
      90deg,
      transparent,
      #000 30%,
      #000 70%,
      transparent 100%
    );
    mask-size: 100% 100%;
    mask-position: center;
    mask-repeat: no-repeat;
  }

  @include break-reverse(768) {
    width: 124%;
  }
}

.image {
  display: block;

  @include break(767) {
    display: flex;
    justify-content: center;
  }
}
