
      @import 'styles-scss/abstracts/mixins';
    
.wrapper {
  @include break-reverse(576) {
    padding-block: calc(var(--space-md) * 2);
    padding-inline: var(--space);
    border: 1px solid var(--primary-60);
    border-radius: 24px;
    box-shadow: inset 0 0 15px 0 rgb(from var(--neutral-25) r g b / 0.1);
    overflow: hidden;
  }

  @include break-reverse(768) {
    padding-top: calc(var(--space-md) * 3);
  }
}

.title {
  margin-bottom: var(--space);
}

.subtitle {
  max-width: 750px;
  margin-bottom: var(--space-lg);
  color: var(--neutral-40);
}

.slider {
  width: 100%;
}

.slide {
  margin: 0 auto;
  padding: var(--space-sm) 0;
  max-width: 480px;
  height: 100%;

  @include break-reverse(768) {
    margin: 0 var(--space-xs);
  }

  .card {
    display: flex;
    flex-direction: column;
    gap: var(--space);
    padding: calc(var(--space-lg) * 0.5);
    height: 100%;
    background: rgb(from var(--primary-95) r g b / 0.5);
    border: 1px solid var(--primary-60);
    border-radius: 16px;
    transition: box-shadow var(--transition);
    overflow: hidden;

    @include break-reverse(768) {
      padding: var(--space) var(--space) var(--space-md);
    }
  }

  .card__active {
    position: relative;
    opacity: 1;

    &:has(.card_link:hover) {
      box-shadow: 0 0 12px 0 rgb(from var(--neutral-80) r g b / 0.6);
    }
  }

  .card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .card_topic {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px var(--space-xs);
    width: fit-content;
    color: var(--neutral-0);
    background-color: var(--primary-60);
    border-radius: 6px;
  }

  .card_link {
    position: static;

    &:before {
      content: '';
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    &:hover .card_icon {
      stroke: var(--neutral-0);
    }
  }

  .card_icon {
    width: var(--space);
    height: var(--space);
    stroke: var(--primary-40);
    stroke-width: 2;
  }

  .card_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--space);
    height: 100%;
  }

  .card_description {
    color: var(--neutral-35);
  }
}

.card_image {
  align-self: start;
  height: 32px;
}

.actions {
  flex-direction: column;
  margin-block: var(--space-md) var(--space-xxl);

  @include break-reverse(768) {
    flex-direction: row;
    margin-block: var(--space-md) calc(var(--space) * 3);
  }
}

.badges {
  position: relative;
  width: 100%;
  padding-top: var(--space-lg);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgb(from var(--primary-40) r g b / 0),
      var(--neutral-40) 50%,
      rgb(from var(--primary-40) r g b / 0)
    );
  }
}
