@layer typography {
  /* Line-height styles */

  .line_height_none {
    line-height: 1.1;
  }

  .line_height_tight {
    line-height: 1.25;
  }

  .line_height_normal {
    line-height: 1.35;
  }

  .line_height_relaxed {
    line-height: 1.45;
  }

  .line_height_loose {
    line-height: 1.6;
  }

  /* Heading styles */

  .heading_xs {
    font-size: 18px;
    font-weight: 500;
  }

  .heading_sm {
    font-size: 20px;
    font-weight: 500;
  }

  .heading_md {
    font-size: 24px;
    font-weight: 500;
  }

  .heading_lg {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.35;
  }

  @media (min-width: 768px) {
    .heading_lg {
      font-size: 28px;
      line-height: 1.25;
    }
  }

  @media (min-width: 992px) {
    .heading_lg {
      font-size: 32px;
    }
  }

  .heading_xl {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.35;
  }

  @media (min-width: 768px) {
    .heading_xl {
      font-size: 28px;
      line-height: 1.25;
    }
  }

  @media (min-width: 992px) {
    .heading_xl {
      font-size: 36px;
    }
  }

  .heading_xxl {
    font-size: 32px;
    line-height: 1.25;
  }

  @media (min-width: 768px) {
    .heading_xxl {
      font-size: 40px;
      line-height: 1.1;
    }
  }

  @media (min-width: 992px) {
    .heading_xxl {
      font-size: 48px;
    }
  }

  /* Text styles */

  .text_xs {
    font-size: 12px;
  }

  .text_sm {
    font-size: 14px;
  }

  .text_md {
    font-size: 16px;
  }

  .text_lg {
    font-size: 18px;
  }

  /* Common text styles */

  .text_balance {
    text-wrap: balance;
  }

  .text_center {
    text-align: center;
  }
}
