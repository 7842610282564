
      @import 'styles-scss/abstracts/mixins';
    
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--space-xl);

  @include break-reverse(768) {
    flex-direction: row;
    gap: var(--space-lg);
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--space);

  @include break-reverse(768) {
    gap: var(--space-lg);
  }
}

.item {
  display: flex;
  justify-content: center;
}

.image {
  width: 100%;
  height: 128px;
}

.button {
  text-align: center;
}
